<template>
  <van-cell-group title="已结算" />
  <van-grid :column-num="3">
    <template v-for="item in a1" :key="item">
      <van-grid-item>
        <p>
          <small>{{ item.count }} 笔</small>
        </p>
        <p>
          <small>{{ item.amount }} 元</small>
        </p>
        <p>
          <small>{{ item.name }}</small>
        </p>
      </van-grid-item>
    </template>
  </van-grid>
  <van-cell-group title="待结算" />
  <van-grid :column-num="3">
    <template v-for="item in a2" :key="item">
      <van-grid-item>
        <p>
          <small>{{ item.count }} 笔</small>
        </p>
        <p>
          <small>{{ item.amount }} 元</small>
        </p>
        <p>
          <small>{{ item.name }}</small>
        </p>
      </van-grid-item>
    </template>
  </van-grid>
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'
import dayjs from 'dayjs'
import WeekDay from 'dayjs/plugin/weekday'

export default {
  setup () {
    const post = inject('post')
    const Cookies = inject('Cookies')
    const state = reactive({
      storeId: Cookies.get('storeId'),
      s: 0,
      a1: [
        {
          name: '今日',
          count: 0,
          amount: 0
        },
        {
          name: '昨日',
          count: 0,
          amount: 0
        },
        {
          name: '本周',
          count: 0,
          amount: 0
        },
        {
          name: '上周',
          count: 0,
          amount: 0
        },
        {
          name: '本月',
          count: 0,
          amount: 0
        },
        {
          name: '上月',
          count: 0,
          amount: 0
        },
        {
          name: '本年',
          count: 0,
          amount: 0
        },
        {
          name: '上年',
          count: 0,
          amount: 0
        },
        {
          name: '累计',
          count: 0,
          amount: 0
        }
      ],
      a2: [
        {
          name: '待收货',
          count: 0,
          amount: 0
        },
        {
          name: '待使用',
          count: 0,
          amount: 0
        },
        {
          name: '待退款',
          count: 0,
          amount: 0
        }
      ]
    })
    const init = () => {
      dayjs.extend(WeekDay)
      post('/order.sumOrder', {
        storeId: state.storeId,
        status: 'COMPLETED,REFUNDED,REFUSE_REFUNDED'.split(','),
        minCompleteTime: dayjs().set('hour', 0).set('minute', 0).set('second', 0).format('YYYY-MM-DD HH:mm:ss'),
        maxCompleteTime: dayjs().set('hour', 23).set('minute', 59).set('second', 59).format('YYYY-MM-DD HH:mm:ss')
      }).then(res => {
        state.a1[0].count = res.data.count
        state.a1[0].amount = res.data.payAmount
      })
      post('/order.sumOrder', {
        storeId: state.storeId,
        status: 'COMPLETED,REFUNDED,REFUSE_REFUNDED'.split(','),
        minCompleteTime: dayjs().subtract(1, 'day').set('hour', 0).set('minute', 0).set('second', 0).format('YYYY-MM-DD HH:mm:ss'),
        maxCompleteTime: dayjs().subtract(1, 'day').set('hour', 23).set('minute', 59).set('second', 59).format('YYYY-MM-DD HH:mm:ss')
      }).then(res => {
        state.a1[1].count = res.data.count
        state.a1[1].amount = res.data.payAmount
      })
      post('/order.sumOrder', {
        storeId: state.storeId,
        status: 'COMPLETED,REFUNDED,REFUSE_REFUNDED'.split(','),
        minCompleteTime: dayjs().weekday(0).set('hour', 0).set('minute', 0).set('second', 0).format('YYYY-MM-DD HH:mm:ss'),
        maxCompleteTime: dayjs().weekday(7).set('hour', 0).set('minute', 0).set('second', 0).format('YYYY-MM-DD HH:mm:ss')
      }).then(res => {
        state.a1[2].count = res.data.count
        state.a1[2].amount = res.data.payAmount
      })
      post('/order.sumOrder', {
        storeId: state.storeId,
        status: 'COMPLETED,REFUNDED,REFUSE_REFUNDED'.split(','),
        minCompleteTime: dayjs().weekday(-7).set('hour', 0).set('minute', 0).set('second', 0).format('YYYY-MM-DD HH:mm:ss'),
        maxCompleteTime: dayjs().weekday(0).set('hour', 0).set('minute', 0).set('second', 0).format('YYYY-MM-DD HH:mm:ss')
      }).then(res => {
        state.a1[3].count = res.data.count
        state.a1[3].amount = res.data.payAmount
      })
      post('/order.sumOrder', {
        storeId: state.storeId,
        status: 'COMPLETED,REFUNDED,REFUSE_REFUNDED'.split(','),
        minCompleteTime: dayjs().startOf('month').set('hour', 0).set('minute', 0).set('second', 0).format('YYYY-MM-DD HH:mm:ss'),
        maxCompleteTime: dayjs().endOf('month').set('hour', 23).set('minute', 59).set('second', 59).format('YYYY-MM-DD HH:mm:ss')
      }).then(res => {
        state.a1[4].count = res.data.count
        state.a1[4].amount = res.data.payAmount
      })
      post('/order.sumOrder', {
        storeId: state.storeId,
        status: 'COMPLETED,REFUNDED,REFUSE_REFUNDED'.split(','),
        minCompleteTime: dayjs().subtract(1, 'month').startOf('month').set('hour', 0).set('minute', 0).set('second', 0).format('YYYY-MM-DD HH:mm:ss'),
        maxCompleteTime: dayjs().subtract(1, 'month').endOf('month').set('hour', 23).set('minute', 59).set('second', 59).format('YYYY-MM-DD HH:mm:ss')
      }).then(res => {
        state.a1[5].count = res.data.count
        state.a1[5].amount = res.data.payAmount
      })
      post('/order.sumOrder', {
        storeId: state.storeId,
        status: 'COMPLETED,REFUNDED,REFUSE_REFUNDED'.split(','),
        minCompleteTime: dayjs().startOf('year').set('hour', 0).set('minute', 0).set('second', 0).format('YYYY-MM-DD HH:mm:ss'),
        maxCompleteTime: dayjs().endOf('year').set('hour', 23).set('minute', 59).set('second', 59).format('YYYY-MM-DD HH:mm:ss')
      }).then(res => {
        state.a1[6].count = res.data.count
        state.a1[6].amount = res.data.payAmount
      })
      post('/order.sumOrder', {
        storeId: state.storeId,
        status: 'COMPLETED,REFUNDED,REFUSE_REFUNDED'.split(','),
        minCompleteTime: dayjs().subtract(1, 'year').startOf('year').set('hour', 0).set('minute', 0).set('second', 0).format('YYYY-MM-DD HH:mm:ss'),
        maxCompleteTime: dayjs().subtract(1, 'year').endOf('year').set('hour', 23).set('minute', 59).set('second', 59).format('YYYY-MM-DD HH:mm:ss')
      }).then(res => {
        state.a1[7].count = res.data.count
        state.a1[7].amount = res.data.payAmount
      })
      post('/order.sumOrder', {
        storeId: state.storeId,
        status: 'COMPLETED,REFUNDED,REFUSE_REFUNDED'.split(',')
      }).then(res => {
        state.a1[8].count = res.data.count
        state.a1[8].amount = res.data.payAmount
      })
      post('/order.sumOrder', {
        storeId: state.storeId,
        status: 'DELIVERED'
      }).then(res => {
        state.a2[0].count = res.data.count
        state.a2[0].amount = res.data.payAmount
      })
      post('/order.sumOrder', {
        storeId: state.storeId,
        status: 'WAIT_USE'
      }).then(res => {
        state.a2[1].count = res.data.count
        state.a2[1].amount = res.data.payAmount
      })
      post('/order.sumOrder', {
        storeId: state.storeId,
        status: 'REFUNDING'
      }).then(res => {
        state.a2[2].count = res.data.count
        state.a2[2].amount = res.data.refundAmount
      })
      // post('/order.sumOrder', {
      //   sellerId: Cookies.get('seller_id')
      // }).then(res => {
      //   state.s = res.data.count
      // })
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state)
    }
  }
}
</script>

<style scoped>
h5 {
  line-height:1.8;
}
</style>
